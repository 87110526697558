<template>
  <div
    class="datetime-container"
    :style="positionStyle"
    @mousedown="startDragging"
  >
    <div class="day-of-week">{{ dayOfWeek }}</div>
    <div class="date">{{ formattedDate }}</div>
    <div class="time">{{ formattedTime }}</div>
    <div class="timezone">{{ timezoneAbbreviation }}</div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import moment from "moment-timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  props: {
    timezone: {
      type: String,
      default: "America/New_York", // Default to Eastern Time
    },
  },
  data() {
    return {
      currentTime: dayjs().tz(this.timezone), // Initialize based on the prop
      timer: null,
      isDragging: false,
      offset: { x: 0, y: 0 },
      position: { top: 20, left: 100 }, // Initial position
    };
  },
  watch: {
    timezone: {
      immediate: true,
      handler(newTimezone) {
        this.currentTime = dayjs().tz(newTimezone); // Update currentTime when timezone changes
      },
    },
  },
  computed: {
    dayOfWeek() {
      return this.currentTime.format("dddd"); // e.g., "Monday"
    },
    formattedDate() {
      return this.currentTime.format("MMMM D, YYYY"); // e.g., "December 9th, 2024"
    },
    formattedTime() {
      return this.currentTime.format("HH:mm:ss"); // 24-hour format, e.g., "14:30:15"
    },
    positionStyle() {
      return {
        position: "absolute",
        top: `${this.position.top}px`,
        left: `${this.position.left}px`,
        cursor: this.isDragging ? "grabbing" : "grab",
      };
    },
    timezoneAbbreviation() {
      try {
        return moment.tz(this.timezone).format('z'); // e.g., "EST", "EDT"
      } catch (error) {
        console.error("Error fetching timezone abbreviation:", error);
        return "";
      }
    },
  },
  methods: {
    updateTime() {
      this.currentTime = dayjs().tz(this.timezone);
    },
    startDragging(event) {
      this.isDragging = true;
      // Calculate the offset between mouse position and component's top-left corner
      this.offset = {
        x: event.clientX - this.position.left,
        y: event.clientY - this.position.top,
      };
      // Add event listeners to handle dragging
      window.addEventListener("mousemove", this.onDrag);
      window.addEventListener("mouseup", this.stopDragging);
    },
    onDrag(event) {
      if (!this.isDragging) return;
      // Update the position based on mouse movement
      this.position = {
        top: event.clientY - this.offset.y,
        left: event.clientX - this.offset.x,
      };
    },
    stopDragging() {
      this.isDragging = false;
      // Remove the event listeners when dragging stops
      window.removeEventListener("mousemove", this.onDrag);
      window.removeEventListener("mouseup", this.stopDragging);
    },
  },
  mounted() {
    // Update the time every second
    this.timer = setInterval(this.updateTime, 1000);
  },
  beforeUnmount() {
    // Clear the timer and remove event listeners when the component is destroyed
    clearInterval(this.timer);
    window.removeEventListener("mousemove", this.onDrag);
    window.removeEventListener("mouseup", this.stopDragging);
  },
};
</script>

<style scoped>
.datetime-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Removed fixed height to allow dynamic positioning */
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0); /* Semi-transparent background */
  padding: 0px;
  border-radius: 1px;
  user-select: none; /* Prevent text selection while dragging */
  z-index: 9;
  align-items: flex-start; /* Align children to the left */
}

.datetime-container:active {
  cursor: grabbing;
  background-color: rgba(0, 0, 0, 0.118); /* Semi-transparent background */
}

.day-of-week {
  font-size: 150px; /* Big bold letters */
  font-weight: light;
  margin-bottom: 5px;
}

.date {
  font-size: 75px;
  margin-bottom: 5px;
}

.time {
  font-size: 36px;
  font-family: 'Courier New', Courier, monospace; /* Monospaced font for clock */
}

.timezone {
  font-size: 16px; /* Slightly smaller font */
  font-style: italic; /* Optional: Italicize the timezone */
  color: #ccc; /* Gray color */
  margin-top: 5px; /* Add some spacing from the time */
}

/* Responsive Design */
@media (max-width: 600px) {
  .day-of-week {
    font-size: 50px;
  }

  .date {
    font-size: 18px;
  }

  .time {
    font-size: 24px;
  }
}
</style>
