<template>
  <div
    class="notepad-widget"
    :style="{ top: position.top + 'px', left: position.left + 'px', backgroundColor: currentColor }"
    @mousedown="startDragging"
  >
    <textarea v-model="content" placeholder="Write here. Note - nothing is ever saved. user be aware."></textarea>

    <!-- Color Picker Toggle -->
    <div class="color-picker-toggle" @click.stop="toggleColorPicker">
      >>
    </div>

    <!-- Color Options -->
    <div v-if="showColorPicker" class="color-options" @click.stop>
      <div
        v-for="color in colors"
        :key="color"
        :style="{ backgroundColor: color }"
        class="color-square"
        @click="changeColor(color)"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotepadWidget",
  data() {
    return {
      content: "",
      isDragging: false,
      position: { top: 100, left: 100 },
      offset: { x: 0, y: 0 },
      currentColor: "#FFF9C4", // Default yellow color
      showColorPicker: false,
      colors: ["#FFF9C4", "#FFCCBC", "#D1C4E9", "#C5E1A5", "#BBDEFB"], // Pastel options
    };
  },
  methods: {
    startDragging(event) {
      this.isDragging = true;
      this.offset = {
        x: event.clientX - this.position.left,
        y: event.clientY - this.position.top,
      };

      window.addEventListener("mousemove", this.onDrag);
      window.addEventListener("mouseup", this.stopDragging);
    },
    onDrag(event) {
      if (!this.isDragging) return;

      this.position = {
        top: event.clientY - this.offset.y,
        left: event.clientX - this.offset.x,
      };
    },
    stopDragging() {
      this.isDragging = false;
      window.removeEventListener("mousemove", this.onDrag);
      window.removeEventListener("mouseup", this.stopDragging);
    },
    toggleColorPicker() {
      this.showColorPicker = !this.showColorPicker;

      // Add click listener to close the color picker when clicking outside
      if (this.showColorPicker) {
        window.addEventListener("click", this.closeColorPicker);
      }
    },
    closeColorPicker() {
      this.showColorPicker = false;
      window.removeEventListener("click", this.closeColorPicker);
    },
    changeColor(color) {
      this.currentColor = color;
      this.showColorPicker = false; // Close picker after selection
    },
  },
};
</script>

<style>
.notepad-widget {
  z-index:9;
  position: absolute;
  width: 300px;
  height: 200px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  resize: none;
  overflow: hidden;
  cursor: grab;
}

.notepad-widget textarea {
  width: 100%;
  height: 100%;
  border: none;
  padding: 10px;
  font-size: 16px;
  resize: none;
  outline: none;
  background: transparent;
}

.notepad-widget:active {
  cursor: grabbing;
}

/* Color Picker Toggle */
.color-picker-toggle {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

/* Color Options */
.color-options {
  position: absolute;
  bottom: 25px;
  right: 5px;
  display: flex;
  gap: 5px;
}

.color-square {
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  transition: transform 0.2s;
}

.color-square:hover {
  transform: scale(1.2);
}
</style>

