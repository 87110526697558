<template>
  <div
    class="timer-container"
    :style="{ 
      top: position.top + 'px', 
      left: position.left + 'px', 
      width: width + 'px', 
      height: height + 'px' 
    }"
    @mousedown="startDragging"
  >
    <!-- Resize Handles -->
    <div class="resize-handle top-left" @mousedown.stop="startResizing('top-left')"></div>
    <div class="resize-handle top-right" @mousedown.stop="startResizing('top-right')"></div>
    <div class="resize-handle bottom-left" @mousedown.stop="startResizing('bottom-left')"></div>
    <div class="resize-handle bottom-right" @mousedown.stop="startResizing('bottom-right')"></div>
    <div class="resize-handle top" @mousedown.stop="startResizing('top')"></div>
    <div class="resize-handle bottom" @mousedown.stop="startResizing('bottom')"></div>
    <div class="resize-handle left" @mousedown.stop="startResizing('left')"></div>
    <div class="resize-handle right" @mousedown.stop="startResizing('right')"></div>

    <!-- Circle Timer Visualization -->
    <div class="circle-timer">
      <svg class="timer-circle" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
        <g transform="rotate(-90 50 50)">
          <circle class="circle-bg" cx="50" cy="50" r="45" />
          <circle class="circle-progress" cx="50" cy="50" r="45" :style="{ strokeDashoffset: strokeDashOffset }" />
        </g>
      </svg>
      <div class="timer-text" @click="editTime">
        <span v-if="!editingTime">{{ formattedTime }}</span>
        <input v-else type="text" v-model="timeInput" @blur="setTime" @keyup.enter="setTime" />
      </div>
    </div>
    <div class="button-group">
      <button class="control-button" @click="togglePause">
        {{ isPaused ? (isStarted ? "Resume" : "Play") : "Pause" }}
      </button>
      <button class="control-button" @click="resetTimer">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M8 1.5A6.5 6.5 0 111.5 8H0a8 8 0 108-8v2l2-2-2-2v2z" fill="currentColor"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CircleTimer",
  props: {
  duration: {
    type: Number,
    default: 30,
  },
  circleMode: {
    type: String,
    default: "build-up", // 'countdown' or 'build-up'
  },
},
  data() {
    return {
      timeRemaining: this.duration,
      totalDuration: this.duration,
      timerInterval: null,
      circumference: 2 * Math.PI * 45,
      isPaused: true,
      isStarted: false, // Initialize from the prop

      // Dragging state
      position: { top: 100, left: 100 },
      isDragging: false,
      offset: { x: 0, y: 0 },

      // Editing state
      editingTime: false,
      timeInput: "",

      // Resizing state
      width: 600, // initial width in px
      height: 600, // initial height in px
      isResizing: false,
      resizeDirection: null,
      initialMousePos: { x: 0, y: 0 },
      initialSize: { width: 300, height: 300 },
      initialPos: { top: 100, left: 100 },
      // Audio state
      startSound: new Audio(require("@/assets/two-beep.mp3")), // Replace with the path to your sound file
      // Audio state
      endSound: new Audio(require("@/assets/10s-beep.mp3")), // Replace with the path to your sound file
      };
  },
  computed: {
    strokeDashOffset() {
      const progress = this.timeRemaining / this.totalDuration;
      return this.circleMode === "countdown"
        ? this.circumference * progress // Full -> Empty
        : this.circumference * (1 - progress); // Empty -> Full
    },
    formattedTime() {
      const minutes = Math.floor(this.timeRemaining / 60).toString().padStart(2, "0");
      const seconds = (this.timeRemaining % 60).toString().padStart(2, "0");
      return `${minutes}:${seconds}`;
    },
  },
  methods: {
    // Dragging
    startDragging(event) {
      if (this.isResizing) return; // If resizing, don’t start dragging
      this.isDragging = true;
      this.offset = {
        x: event.clientX - this.position.left,
        y: event.clientY - this.position.top
      };
      window.addEventListener("mousemove", this.onDrag);
      window.addEventListener("mouseup", this.stopDragging);
    },
    onDrag(event) {
      if (!this.isDragging) return;
      this.position = {
        top: event.clientY - this.offset.y,
        left: event.clientX - this.offset.x
      };
    },
    stopDragging() {
      this.isDragging = false;
      window.removeEventListener("mousemove", this.onDrag);
      window.removeEventListener("mouseup", this.stopDragging);
    },

    // Resizing
    startResizing(direction) {
      this.isResizing = true;
      this.resizeDirection = direction;
      this.initialMousePos = { x: event.clientX, y: event.clientY };
      this.initialSize = { width: this.width, height: this.height };
      this.initialPos = { top: this.position.top, left: this.position.left };

      window.addEventListener("mousemove", this.onResize);
      window.addEventListener("mouseup", this.stopResizing);
    },
    onResize(event) {
      if (!this.isResizing) return;
      const dx = event.clientX - this.initialMousePos.x;
      const dy = event.clientY - this.initialMousePos.y;

      let newWidth = this.initialSize.width;
      let newHeight = this.initialSize.height;
      let newTop = this.initialPos.top;
      let newLeft = this.initialPos.left;

      switch (this.resizeDirection) {
        case 'top':
          newTop = this.initialPos.top + dy;
          newHeight = this.initialSize.height - dy;
          break;
        case 'bottom':
          newHeight = this.initialSize.height + dy;
          break;
        case 'left':
          newLeft = this.initialPos.left + dx;
          newWidth = this.initialSize.width - dx;
          break;
        case 'right':
          newWidth = this.initialSize.width + dx;
          break;
        case 'top-left':
          newTop = this.initialPos.top + dy;
          newHeight = this.initialSize.height - dy;
          newLeft = this.initialPos.left + dx;
          newWidth = this.initialSize.width - dx;
          break;
        case 'top-right':
          newTop = this.initialPos.top + dy;
          newHeight = this.initialSize.height - dy;
          newWidth = this.initialSize.width + dx;
          break;
        case 'bottom-left':
          newHeight = this.initialSize.height + dy;
          newLeft = this.initialPos.left + dx;
          newWidth = this.initialSize.width - dx;
          break;
        case 'bottom-right':
          newWidth = this.initialSize.width + dx;
          newHeight = this.initialSize.height + dy;
          break;
      }

      // Enforce minimum size if desired
      newWidth = Math.max(100, newWidth);
      newHeight = Math.max(100, newHeight);

      this.width = newWidth;
      this.height = newHeight;
      this.position.top = newTop;
      this.position.left = newLeft;
    },
    stopResizing() {
      this.isResizing = false;
      window.removeEventListener("mousemove", this.onResize);
      window.removeEventListener("mouseup", this.stopResizing);
    },

    // Timer logic
    startTimer() {
      clearInterval(this.timerInterval);
      this.timerInterval = setInterval(() => {
        if (!this.isPaused && this.timeRemaining > 0) {
          this.timeRemaining--;
        }
        if (this.timeRemaining === 0) {
          clearInterval(this.timerInterval);
          this.endSound.play().catch((error) => {
            console.error("End sound playback failed:", error);
          });
          //this.$emit("timer-finished");
        }
      }, 1000);
    },
    togglePause() {
      if (this.timeRemaining === 0) return; // Prevent actions when time is up

      if (this.isPaused) {
        if (!this.isStarted) {
          // Play sound only on the first start
          this.startSound.play().catch((error) => {
            console.error("Sound playback failed:", error);
          });
          this.isStarted = true; // Update local state
          this.$emit("started", true); // Optional: Notify parent if needed
        }
        this.startTimer(); // Start or resume the timer
      } else {
        clearInterval(this.timerInterval); // Pause the timer
      }

      this.isPaused = !this.isPaused; // Toggle the paused state
    },




    resetTimer() {
      clearInterval(this.timerInterval);
      this.timeRemaining = this.totalDuration;
      this.isPaused = true;
      this.isStarted = false; // Reset local state
      this.$emit("started", false); // Optional: Notify parent if needed
    },





    // Editing time
    editTime() {
      this.editingTime = true;
      this.timeInput = this.formattedTime;
    },
    setTime() {
      const input = this.timeInput.trim();

      // Check if input includes a colon:
      if (input.includes(":")) {
        const [minutesStr, secondsStr] = input.split(":");
        const minutes = parseInt(minutesStr, 10);
        const seconds = parseInt(secondsStr, 10);

        if (
          !isNaN(minutes) && !isNaN(seconds) &&
          minutes >= 0 && minutes <= 99 &&
          seconds >= 0 && seconds <= 59
        ) {
          const newTime = minutes * 60 + seconds;
          this.timeRemaining = newTime;
          this.totalDuration = newTime;
        }
      } else {
        // No colon means user only typed seconds (or a whole number)
        const seconds = parseInt(input, 10);
        if (!isNaN(seconds) && seconds >= 0 && seconds <= 5999) { // up to 99:59
          this.timeRemaining = seconds;
          this.totalDuration = seconds;
        }
      }

      this.editingTime = false;
      this.isPaused = true;
      clearInterval(this.timerInterval);
    }
,
  },
  mounted() {
  this.$nextTick(() => {
    const desktopEl = this.$root.$refs.desktop;
    const containerWidth = desktopEl.clientWidth;
    const containerHeight = desktopEl.clientHeight;

    // Center the timer
    this.position.top = (containerHeight - this.height) / 2;
    this.position.left = (containerWidth - this.width) / 2;

    this.startTimer();
  });
},
};
</script>

<style>
.timer-container {
  position: absolute;
  max-width: 600px;
  max-height: 600px;
  min-width: 250px;
  min-height: 250px;
  z-index: 10;
  border: 0px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f900;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: grab;
}


.timer-container:active {
  cursor: grabbing;
  background-color: #f9f9f947;
}

.circle-timer {
  width: 90%; /* Adjust based on how much space you want it to take */
  max-width: 600px; /* Ensures it doesn’t grow too large */
  height: auto;
  aspect-ratio: 1 / 1; /* Maintain a perfect circle */
  margin: 0 auto; /* Center it horizontally */
}


.timer-circle {
  width: 100%;
  height: 100%;
  
}
.timer-circle svg {
  width: 100%;
  height: 100%;
}
.circle-bg {
  fill: none;
  stroke: #e6e6e600;
  stroke-width: 10;
}
.circle-progress {
  fill: none;
  stroke: var(--progress-bar-color, #ffffff9b); /* Default to blue if not set */
  stroke-width: 5;
  stroke-dasharray: 282.74;
  stroke-dashoffset: 282.74;
  transition: stroke-dashoffset 1s linear;
}
.timer-text {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 100px; /* Increase the font size */
  font-weight: light;
  color: #ffffff; /* Adjust the color if needed */
  text-align: center; /* Center align the text */
  line-height: 1; /* Ensure no extra spacing around the text */
  cursor: pointer;
}




.timer-text input {
  width: 100%; /* Increase the width for better editing space */
  text-align: center;
  font-size: 100px; /* Increase the font size */
  font-weight: light;
  border: 2px solid #000000;
  border-radius: 10px;
  outline: none; /* Remove outline for focus */
  color: #381515; /* Match the text color */
  background: none; /* Transparent background */
  animation: blink-border 3s infinite; /* Add animation */
  animation: blink-border2 3s infinite; /* Add animation */

}

/* Define the blinking animation */
@keyframes blink-border {
  0% {
    border-color: #000000; /* Initial border color */
  }
  50% {
    border-color: transparent; /* Make the border invisible */
  }
  100% {
    border-color: #000000; /* Restore the border color */
  }
}
@keyframes blink-border2 {
  0% {
    color: #381515; 
  }
  50% {
    color: #38151565; /* Match the text color */
  }
  100% {
    color: #381515; /* Match the text color */
  }
}



.button-group {
  display: flex;
  gap: 10px; /* Adjust spacing as desired */
  justify-content: center; /* Center the buttons */
  align-items: center;
}


.control-button {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  background: #3333333b;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  
}

/* Resize handle styling */
.resize-handle {
  position: absolute;
  background: rgba(233, 233, 233, 0);
  border-radius: 2px;
}

/* 8 handles for corners and sides */
.resize-handle.top-left {
  top: 0; left: 0;
  width: 10px; height: 10px;
  cursor: nwse-resize;
}
.resize-handle.top-right {
  top: 0; right: 0;
  width: 10px; height: 10px;
  cursor: nesw-resize;
}
.resize-handle.bottom-left {
  bottom: 0; left: 0;
  width: 10px; height: 10px;
  cursor: nesw-resize;
}
.resize-handle.bottom-right {
  bottom: 0; right: 0;
  width: 10px; height: 10px;
  cursor: nwse-resize;
  
}

.resize-handle.top {
  top: 0; left: 50%;
  width: 10px; height: 10px;
  transform: translateX(-50%);
  cursor: ns-resize;
}
.resize-handle.bottom {
  bottom: 0; left: 50%;
  width: 10px; height: 10px;
  transform: translateX(-50%);
  cursor: ns-resize;
}
.resize-handle.left {
  left: 0; top: 50%;
  width: 10px; height: 10px;
  transform: translateY(-50%);
  cursor: ew-resize;
}
.resize-handle.right {
  right: 0; top: 50%;
  width: 10px; height: 10px;
  transform: translateY(-50%);
  cursor: ew-resize;
}

@media (max-width: 600px) {
  .timer-container {
    background-color: #f9f9f900;
  }
  
  
  .timer-text {
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px; /* Increase the font size */
    font-weight: light;
    color: #ffffff; /* Adjust the color if needed */
    text-align: center; /* Center align the text */
    line-height: 1; /* Ensure no extra spacing around the text */
    cursor: pointer;
  }

  .timer-text input {
    width: 100%; /* Increase the width for better editing space */
    text-align: center;
    font-size: 50px; /* Increase the font size */
    font-weight: light;
    border: none; /* Remove default input border */
    outline: none; /* Remove outline for focus */
    color: #333; /* Match the text color */
    background: none; /* Transparent background */
  }




  .circle-timer {
    width: 100%;
    max-width: 250px;
  }
}


</style>
