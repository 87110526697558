<template>
  <div id="app">
    <!-- Hamburger Menu -->
    <div class="hamburger-menu" :class="{ active: isMenuOpen }">
      <div class="hamburger-icon" ref="hamburger" @click="toggleMenu">☰</div>
      <div class="menu" ref="menu" v-if="isMenuOpen">
        <h2 class="menu-title">spaceforfocus.com</h2>

        <!-- Widget Toggles -->
        <div
          v-for="widget in widgets"
          :key="widget.name"
          class="menu-item"
        >
          <label>
            <input
              type="checkbox"
              :checked="widget.visible"
              @change="toggleWidget(widget.name)"
            />
            {{ widget.label }}
          </label>
        </div>

        <hr />

        <!-- Preset Color Templates -->
        <div class="menu-section">
          <h3>Background Themes</h3>
          <div class="menu-item">
            <label>
              Theme:
              <select v-model="selectedTheme" @change="applyTheme">
                <option v-for="(theme, key) in themes" :key="key" :value="key">
                  {{ theme.name }}
                </option>
              </select>
            </label>
          </div>
        </div>

        <!-- New Circle Appearance Toggle -->
        <div class="menu-section">
          <h3>Timer Options</h3>


          <!-- Add this inside Timer Options -->
          <div class="menu-item">
            <label>
              Timezone:
              <select v-model="selectedTimezone">
                <option v-for="(zone, key) in timezones" :key="key" :value="key">
                  {{ zone }}
                </option>
              </select>


            </label>
          </div>




          <div class="menu-item">
            <label>
              <input
                type="checkbox"
                :checked="circleAppearance === 'countdown'"
                @change="toggleCircleAppearance"
              />
              Build-up Mode
            </label>
          </div>
          <div class="menu-item">
            <label>
              Progress Bar Color:
              <input
                type="color"
                v-model="progressBarColor"
                @input="updateProgressBarColor"
              />
            </label>
          </div>
        </div>

      </div>
    </div> <!-- END NAVINGATION -->

    <!-- Desktop Background and Content -->
    <div class="desktop" ref="desktop">
      <NotepadWidget v-if="widgets[0].visible" />
      <CircleTimer
        v-if="widgets[1].visible"
        :duration="1500"
        :circle-mode="circleAppearance"
        @timer-finished="onTimerFinished"
        @started="handleTimerStarted" 
      />

      <DateTime
        v-if="widgets.find(w => w.name === 'datetime').visible"
        :timezone="selectedTimezone"
      />

    </div>
  </div>
</template>



<script>
import NotepadWidget from "./components/NotepadWidget.vue";
import CircleTimer from "./components/CircleTimer.vue";
import DateTime from "./components/DateTime.vue"; // Import DateTime component


export default {
  name: "App",
  components: {
    NotepadWidget,
    CircleTimer,
    DateTime, // Register DateTime component

  },
  data() {
    return {
      isMenuOpen: false,
      widgets: [
        { name: "notepad", label: "Notepad", visible: false },
        { name: "timer", label: "Circle Timer", visible: true },
        { name: "datetime", label: "Date & Time", visible: true }, // Add DateTime widget

      ],
      circleAppearance: "build-up", // Default to countdown mode
      progressBarColor: "#ffffff", // Default progress bar color
      selectedTheme: "pastel", // Default theme
      themes: {
        pastel: {
          name: "Pastel",
          colors: ["f6a6b2", "b28ad6", "88d8b0", "82c4c3", "fddfa3", "f9c6d9"],
        },
        bright: {
          name: "Bright",
          colors: ["ff5733", "ffbd33", "75ff33", "33ffbd", "3375ff", "bd33ff"],
        },
        earth: {
          name: "Earth",
          colors: ["a0522d", "deb887", "8b4513", "556b2f", "6b8e23", "bdb76b"],
        },
        ocean: {
          name: "Ocean",
          colors: ["4682b4", "5f9ea0", "00ced1", "20b2aa", "48d1cc", "87ceeb"],
        },
        sunset: {
          name: "Sunset",
          colors: ["ff4500", "ff6347", "ffa07a", "ffd700", "ff8c00", "ff1493"],
        },
        forest: {
          name: "Forest",
          colors: ["013220", "2e8b57", "3cb371", "66cdaa", "8fbc8f", "556b2f"],
        },
        monochrome: {
          name: "Monochrome",
          colors: ["000000", "333333", "666666", "999999", "cccccc", "ffffff"],
        },
        retro: {
          name: "Retro",
          colors: ["f4a261", "e76f51", "2a9d8f", "264653", "e9c46a", "f4f1de"],
        },
        neon: {
          name: "Neon",
          colors: ["39ff14", "ff073a", "ff6ec7", "ff61f6", "0ffef9", "a239ca"],
        },
        vintage: {
          name: "Vintage",
          colors: ["c19a6b", "d2b48c", "e5ccaf", "bfa06f", "a98260", "8b5a2b"],
        
        },
      },
      
      colors: [], // Dynamically updated from selected theme
     
      selectedTimezone: "America/New_York", // Default timezone
      timezones: {
        // United States
        "America/New_York": "Eastern Time (EST)",
        "America/Chicago": "Central Time (CST)",
        "America/Denver": "Mountain Time (MST)",
        "America/Los_Angeles": "Pacific Time (PST)",
        "America/Anchorage": "Alaska Time (AKT)",
        "Pacific/Honolulu": "Hawaii-Aleutian Time (HAT)",

        // Asia
        "Asia/Shanghai": "China Standard Time (CST)",
        "Asia/Kolkata": "India Standard Time (IST)",
        "Asia/Tokyo": "Japan Standard Time (JST)",
        "Asia/Jakarta": "Western Indonesia Time (WIB)",
        "Asia/Dhaka": "Bangladesh Standard Time (BST)",
        "Asia/Manila": "Philippine Time (PHT)",
        "Asia/Ho_Chi_Minh": "Indochina Time (ICT)",
        "Asia/Seoul": "Korea Standard Time (KST)",
        "Asia/Bangkok": "Thailand Time (ICT)",

        // Europe
        "Europe/London": "Greenwich Mean Time (GMT)",
        "Europe/Berlin": "Central European Time (CET)",
        "Europe/Paris": "Central European Time (CET)",
        "Europe/Moscow": "Moscow Standard Time (MSK)",
        "Europe/Madrid": "Central European Time (CET)",
        "Europe/Rome": "Central European Time (CET)",
        "Europe/Istanbul": "Turkey Time (TRT)",
        "Europe/Kiev": "Eastern European Time (EET)",
        "Europe/Warsaw": "Central European Time (CET)",

        // South America
        "America/Sao_Paulo": "Brasília Time (BRT)",
        "America/Bogota": "Colombia Time (COT)",
        "America/Lima": "Peru Time (PET)",

        // Africa
        "Africa/Lagos": "West Africa Time (WAT)",
        "Africa/Cairo": "Eastern European Time (EET)",
        "Africa/Johannesburg": "South Africa Standard Time (SAST)",

        // Middle East
        "Asia/Riyadh": "Arabia Standard Time (AST)",
        "Asia/Tehran": "Iran Standard Time (IRST)",

        // Oceania
        "Australia/Sydney": "Australian Eastern Time (AET)",
        "Pacific/Auckland": "New Zealand Standard Time (NZST)",

        // Additional Popular Timezones
        "Europe/Amsterdam": "Central European Time (CET)",
        "Europe/Brussels": "Central European Time (CET)",
        "Europe/Dublin": "Greenwich Mean Time (GMT)",
        "Asia/Hong_Kong": "Hong Kong Time (HKT)",
        "Asia/Singapore": "Singapore Time (SGT)",
      }

    };
  },
  mounted() {
    this.updateProgressBarColor();
    document.addEventListener("click", this.handleOutsideClick);
    this.initializeThemeFromUrl(); // Ensure theme is loaded and applied on mount

  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
  watch: {
    selectedTheme(newTheme) {
      if (this.themes[newTheme]) {
        this.applyTheme(); // Apply the selected theme
        this.updateUrl();  // Update the URL with the new theme
      } else {
        console.warn(`Theme "${newTheme}" is not valid.`);
      }
    },
    colors: {
      immediate: true,
      handler() {
        this.applyGradient();
      },
    },
    
  },

  created() {
    this.initializeThemeFromUrl();
    },
  
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    handleOutsideClick(event) {
      const menu = this.$refs.menu;
      const hamburger = this.$refs.hamburger;
      if (menu && hamburger && !menu.contains(event.target) && !hamburger.contains(event.target)) {
        this.closeMenu();
      }
    },
    toggleWidget(widgetName) {
      const widget = this.widgets.find((w) => w.name === widgetName);
      if (widget) {
        widget.visible = !widget.visible;
      }
    },
    
    toggleCircleAppearance() {
      this.circleAppearance = this.circleAppearance === "countdown" ? "build-up" : "countdown";
    },
    updateProgressBarColor() {
      document.documentElement.style.setProperty("--progress-bar-color", this.progressBarColor);
    },
    onTimerFinished() {
      alert("Timer Finished!");
    },


    applyTheme() {
      const themeColors = this.themes[this.selectedTheme]?.colors || [];
      if (themeColors.length) {
        this.colors = themeColors;
        this.applyGradient();
        console.log(`Applied theme: ${this.selectedTheme}`);
      } else {
        console.warn(`No colors found for the selected theme: "${this.selectedTheme}". Falling back to default theme.`);
        this.selectedTheme = "pastel"; // Fallback to default theme
        this.applyTheme();
      }
    },

    updateUrl() {
      if (!this.themes[this.selectedTheme]) {
        console.warn(`Attempted to set invalid theme: "${this.selectedTheme}". URL not updated.`);
        return;
      }
      const params = new URLSearchParams(window.location.search);
      params.set("theme", this.selectedTheme);
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.replaceState({}, "", newUrl); // Updates the URL without reloading the page
    },
    initializeThemeFromUrl() {
      const params = new URLSearchParams(window.location.search);
      const theme = params.get("theme");
      if (theme && this.themes[theme]) {
        this.selectedTheme = theme; // Initialize with the theme from the URL
      } else {
        this.selectedTheme = "pastel"; // Fallback to default theme if none is found
      }
      this.applyTheme(); // Apply the theme immediately
    },
    applyGradient() {
  if (!this.colors.length) {
    console.warn("No colors found for the selected theme. Gradient not applied.");
    return;
  }
  const prefixedColors = this.colors.map((c) => `#${c}`);
  const gradientColors = [...prefixedColors, ...prefixedColors.slice().reverse()];
  const gradientStr = `linear-gradient(var(--angle, 90deg), ${gradientColors.join(", ")})`;
  document.documentElement.style.setProperty("--gradient", gradientStr);
  console.log(`Gradient applied with colors: ${prefixedColors.join(", ")}`);
},

    
    
    changeTheme(themeKey) {
      if (this.themes[themeKey]) {
        this.selectedTheme = themeKey;
        this.applyTheme();
      }
    },

    handleTimerStarted(status) {
      console.log("Timer started:", status);
      // Implement any additional behavior as needed
    },




  },
};
</script>


<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  
}

#app {
  height: 100%;
  margin: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
}

/* Hamburger Menu Styles */
.hamburger-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 0; /* Start closed */
  height: 100%;
  background-color: rgba(44, 44, 44, 0.893); /* Dark overlay */
  overflow-x: hidden;
  transition: width 0.3s ease; /* Smooth animation */
  z-index: 1000;
}

.hamburger-menu.active {
  width: 300px; /* Open menu width */
}

.hamburger-menu .hamburger-icon {
  position: fixed;
  top: 15px;
  left: 15px;
  font-size: 24px;
  background-color: #50505083;
  color: white;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1100; /* Above the menu */
}

.hamburger-menu .menu {
  display: flex;
  flex-direction: column;
  margin-top: 60px; /* Space for the hamburger icon */
  padding: 20px;
  color: white;
}

.hamburger-menu .menu-item {
  margin-bottom: 20px;
  font-size: 18px;
}

.hamburger-menu .menu-section h3 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #fff;
}

.color-item label {
  justify-content: space-between;
}

.color-item input[type="color"] {
  margin-left: 10px;
  width: 40px;
  height: 30px;
  border: none;
  padding: 0;
}

/* Desktop Background */
.desktop {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

.desktop h1 {
  position: absolute;
  top: -10px;
  left: 60px; /* adjust as needed to appear next to the hamburger menu */
  margin: 0;
}

.desktop::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300vw;
  height: 300vh;
  transform: translate(-50%, -50%);
  background: var(--gradient);
  background-size: 400% 400%;
  animation: gradientScroll 160s linear infinite, rotateGradient 120s linear infinite;
  z-index: -1;
}

@keyframes gradientScroll {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes rotateGradient {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.desktop h1 {
  z-index: 1;
  margin: 0;
  padding: 20px 0;
  color: #333;
  text-align: center;
}

.menu-title {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: white; /* or adjust to match your design */
  text-align: center; /* Center-align the title */
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); /* Optional: adds a subtle separator */
  padding-bottom: 10px;
}

@media (max-width: 600px) {
  .hamburger-menu .hamburger-icon {
    top: 1vw;
    left: 1vw;
    font-size: 24px;
    padding: 8px;
    border-radius: 1px;
}

}
</style>

